import React from 'react';
import { Helmet } from 'react-helmet';
// import { Grid } from '@material-ui/core';
import './index.css'
import FooterSection from '../../sections/FooterSection';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import arrowRight from "../../assets/img/arrow-right.png";
import appdownloadimg from '../../assets/img/app-download-edited.png'
import appdownloadimg1 from '../../assets/img/app-download-2.png'
import appdownloadimg2 from '../../assets/img/app-download-1.png'
import AppleIcon from '@material-ui/icons/Apple';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AndroidIcon from '@material-ui/icons/Android';

class AppDownload extends React.Component {

    componentDidMount() {
        setTimeout(() => {
            const translation = window.localStorage.getItem("translation")
            if (translation)
                this.changeLanguage(translation)
        }, 1000);
        document.documentElement.scrollTop = 0;

        // const script = document.createElement("script");
        // script.src="https://www.googletagmanager.com/gtag/js?id=G-V36Z6S94WF"
        // script.async = true;
        // document.body.appendChild(script);

        // const script2 = document.createElement("script");
        // script2.src= "/gtag-script.js"
        // script2.async = true;
        // document.body.appendChild(script2);
       

        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return window.location.replace("https://play.google.com/store/apps/details?id=com.octux.jobs&hl=en&gl=US")
        }
    
        if (/android/i.test(userAgent)) {
            return window.location.replace("https://play.google.com/store/apps/details?id=com.octux.jobs&hl=en&gl=US")
        }
    
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return window.location.replace("https://apps.apple.com/sg/app/octomate-jobs/id6475540062")
        }
        
    }

    changeLanguage(value) {
        window.localStorage.setItem("translation", value)
        this.props.i18n.changeLanguage(value);
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Helmet>
                    <title>Octomate - {t(`APPDOWNLOAD_PAGE.APPDOWNLOAD`)}</title>
                    <meta name="description" content={t("APPDOWNLOAD_PAGE.DESCMETA")} />
                </Helmet>
                <div className='download-main-container'>
                    {/* Image Container */}
                    <div className='download-image-container'>
                        <div className='image-container'>
                            <img className='download-main-image' src={appdownloadimg1} alt='main'  />
                            <img className='download-main-image' src={appdownloadimg2} alt='main' />
                            <img className='download-main-image' src={appdownloadimg} alt='main'/>
                        </div>
                    </div>
                    <div className='download-links-container'>
                        {/* Heading */}
                        <div>
                            <h1 className='download-Title'>{t("APPDOWNLOAD_PAGE.HeroTitle")}</h1>
                            <h3 className='download-subtitle'>
                                {t("APPDOWNLOAD_PAGE.HeroSubTitle")}
                            </h3>
                        </div>

                        <div className='buttons-container'>
                            {/* Jobs */}
                            <div className='category-container'>
                                <p className='category-title'>Octomate Jobs: </p>
                                <a href='https://play.google.com/store/apps/details?id=com.octux.jobs&hl=en_SG&gl=US' target='_blank' rel="noopener noreferrer">
                                    <button className='btn-case-hyperlink download-btn'>
                                        <PlayArrowIcon style={{ color: '#fff', marginRight: "3px", fontSize: "1.5458333333333333vw" }} className='download-icon'/>
                                        Google Store
                                    </button>
                                </a>
                                <a href='https://apps.apple.com/sg/app/octomate-jobs/id6475540062' target='_blank' rel="noopener noreferrer">
                                    <button className='btn-case-hyperlink download-btn'>
                                        <AppleIcon style={{ color: '#fff', marginRight: "5px", fontSize: "1.5458333333333333vw" }} className='download-icon'/>
                                        Apple Store</button>
                                </a>
                                <a href='https://3a1pid684sc2ifyy09rn8s93.blob.core.windows.net/octomate-landing/octomate-jobs-1.5.2.apk' download rel="noopener noreferrer">
                                    <button className='btn-case-hyperlink download-btn'>
                                        <AndroidIcon style={{ color: '#fff', marginRight: "10px", fontSize: "1.3458333333333333vw" }} className='download-icon'/>
                                        Android Link</button>
                                </a>
                            </div>

                            {/* HR */}
                            <div className='category-container'>
                                <p className='category-title'>Octomate HR: </p>
                                <a href='https://play.google.com/store/apps/details?id=com.octux&hl=en_SG&gl=US' target='_blank' rel="noopener noreferrer">
                                    <button className='btn-case-hyperlink download-btn'>
                                        <PlayArrowIcon style={{ color: '#fff', marginRight: "3px", fontSize: "1.5458333333333333vw" }} className='download-icon'/>
                                        Google Store
                                    </button>
                                </a>
                                <a href='https://apps.apple.com/sg/app/octomate-hr/id1494852495' target='_blank' rel="noopener noreferrer">
                                    <button className='btn-case-hyperlink download-btn'>
                                        <AppleIcon style={{ color: '#fff', marginRight: "5px", fontSize: "1.5458333333333333vw" }} className=''/>
                                        Apple Store</button>
                                </a>
                                <a href='https://3a1pid684sc2ifyy09rn8s93.blob.core.windows.net/octomate-landing/octomate-hr-2.17.2.apk' download rel="noopener noreferrer">
                                    <button className='btn-case-hyperlink download-btn'>
                                        <AndroidIcon style={{ color: '#fff', marginRight: "10px", fontSize: "1.3458333333333333vw" }} className='download-icon'/>
                                        Android Link</button>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Contant Us */}
                <div className={'modules-contact-us'}>
                    <h3>{t(`MODULE_PAGE.Still have questions? Get in`)} <br />
                        {t(`MODULE_PAGE.touch and share your concerns`)}
                    </h3>
                    <Link to="/contactus">
                        <button className={"btn-schedule-demo-action mt-0"}>
                            {t(`MODULE_PAGE.Contact Us`)}
                            <img src={arrowRight} alt="go" className={"arr-demo"} />
                        </button>
                    </Link>
                </div>

                {/* Footer */}
                <div className={'w-100'}>
                    <FooterSection t={t} openFormModal={this.openFormModal} openPrivacyModal={this.openPrivacyModal} />
                </div>
            </>
        )
    }
}

export default withTranslation()(AppDownload)